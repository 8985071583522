<template>
    <AppPainel>

        <Head title="Painel"></Head>
        <div>
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Solicitante</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/home">Home</a></li>
                                <li class="breadcrumb-item active">Solicitante</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>
            <section class="content">

                <!-- Default box -->
                <div class="card card-solid">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <h3 class="d-inline-block d-sm-none">{{ solicita }}
                                </h3>
                                <div v-if="imageview != null" class="col-12">
                                    <img :src="'/storage/' + imageview" class="product-image" alt="Imagem">
                                </div>
                                <div v-else class="col-12">
                                    <img src="/dist/img/semimagem.png" class="product-image" alt="Imagem">
                                </div>
                                <div class="col-12 product-image-thumbs">
                                    <div v-for="(im, index) in solicita.images" :key="index"
                                        class="product-image-thumb active">
                                        <img @load="pegar(im.path)" :src="'/storage/' + im.path" :alt="index">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6">
                                <h3 class="my-3">{{ solicita.nome }}</h3>
                                <p v-if="solicita.mensagem == ''">Sem mensagem</p>
                                <p v-else>{{ solicita.mensagem }}.</p>
                                <hr>
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <h3 class="card-title">Dados do solicitante</h3>
                                    </div>
                                    <div class="card-body">
                                        <strong><i class="fas fa-book mr-1"></i> CPF</strong>
                                        <p class="text-muted">
                                            {{ solicita.cpf }}
                                        </p>
                                        <hr>
                                        <strong><i class="fas fa-map-marker-alt mr-1"></i> Data Nasc.</strong>
                                        <p class="text-muted">{{ solicita.nascimento }}</p>
                                        <hr>
                                        <strong><i class="fas fa-pencil-alt mr-1"></i> Telefone</strong>
                                        <p class="text-muted">
                                            <span class="tag tag-danger">{{ solicita.telefone }}</span>
                                        </p>
                                        <hr>
                                        <strong><i class="far fa-file-alt mr-1"></i> Status</strong>
                                        <div v-if="solicita.status == 1" class="color-palette-set text-center">
                                            <div class="bg-success color-palette"><span>Atendido</span></div>
                                        </div>
                                        <div v-else class="color-palette-set text-center">
                                            <div class="bg-danger color-palette"><span>Não atendido</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bg-gray py-2 px-3 mt-4">
                                    <h2 class="mb-0">
                                        OUTROS
                                    </h2>
                                    <h4 class="mt-0">
                                        <small>Sem dados </small>
                                    </h4>
                                </div>
                                <div class="mt-4">
                                    <div @click="atender(solicita.id)" class="btn btn-primary btn-lg btn-flat">
                                        <i class="fas fa-users fa-lg mr-2"></i>
                                        Atender
                                    </div>
                                    <button @click="recusar(solicita.id, solicita.nome, solicita.email)"
                                        class="btn btn-danger btn-lg btn-flat" data-toggle="modal"
                                        data-target="#modal-recusar">
                                        <i class="fas fa-trash fa-lg mr-2"></i>
                                        Recusar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="modal fade" id="modal-recusar">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Mensagem</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form action="/recusar" method="POST">
                                <div class="card">
                                    <div class="card-body row">
                                        <div class="col text-center d-flex align-items-center justify-content-center">
                                            <div class="">
                                                <h2><strong>SESDEM</strong></h2>
                                                <p class="lead mb-5">
                                                    Rua Felipe Camarão, 934 - 960 - Centro, Mossoró - RN, 59603-340<br>
                                                    E-mail: atendimentotransito@prefeiturademossoro.com.br
                                                    Fone: 84 3315-5008 / 84 98827-8902 (WhatsApp).
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <input type="hidden" name="_token" v-bind:value="csrfToken" />
                                            <input type="hidden" :value="solicita.id" id="id" name="id"
                                                class="form-control" />
                                            <div class="form-group">
                                                <label for="nome">Nome</label>
                                                <input type="text" name="nome" :value="solicita.nome" required
                                                    class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="email" name="email" :value="solicita.email" required
                                                    class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label for="assunto">Assunto</label>
                                                <input type="text" name="assunto" required class="form-control" />
                                            </div>
                                            <div class="form-group">
                                                <label for="mensagem">Mensagem</label>
                                                <textarea name="mensagem" required class="form-control"
                                                    rows="4"></textarea>
                                            </div>
                                            <div class="form-group">
                                                <input type="submit" class="btn btn-primary" value="Envia menssagem">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-default" data-dismiss="modal">
                                Feachar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppPainel>
</template>
<script>
import Vue from "vue";
import AppPainel from "./Auth/Dashboard.vue";
import { Head } from "@inertiajs/inertia-vue";
import axios from "axios";
import config from "../config.js";
axios.create({
    baseURL: config.baseUrl,
});

export default {
    props: ['csrfToken', 'solicita'],
    data() {
        return {
            imageview: null,
            status: null,
            name: '',
            email: '',
            idUser: ''
        }
    },
    mounted() {
        $('.product-image-thumb').on('click', function () {
            var $image_element = $(this).find('img')
            $('.product-image').prop('src', $image_element.attr('src'))
            $('.product-image-thumb.active').removeClass('active')
            $(this).addClass('active')
        })
    },
    methods: {
        pegar(e) {
            this.imageview = e;
        },
        async atender(id) {
            if (confirm('Um e-email será enviado ao solicitante confirmando a conclusão do cartão, CONFIRMAR?')) {
                const response = await axios.post("/api/atender/" + id);
                this.solicita.status = 1;
                console.log(response.data)
            }
        },
        recusar(i, n, em) {
            this.idUser = i;
            this.name = n;
            this.email = em;

        }
    },
    components: {
        Head,
        AppPainel,
    },
};
</script>