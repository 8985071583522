<template>
  <AppPainel>

    <Head title="Home"></Head>

    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0">Home</h1>
      </div>
      <div class="col-sm-6">
        <ol class="breadcrumb float-sm-right">
          <li class="breadcrumb-item active">Home</li>
        </ol>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div class="card-header">Example home</div>
          <div class="card-body">I'm an example component home.</div>
        </div>
      </div>
    </div>
  </AppPainel>
</template>
<script>
import Vue from "vue";
import AppPainel from "./Auth/Dashboard.vue";
import { Head } from "@inertiajs/inertia-vue";
export default {
  data() {
    return {
    }
  },
  components: {
    Head,
    AppPainel,
  },
};
</script>
