<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Usuários do sistema</h3>
      </div>
      <!-- /.card-header -->
      <div class="card-body">
        <table id="example2" class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>CPF</th>
              <th>Tipo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(allUser, index) in allUsers" :key="index">
              <td>
                {{ allUser.name }}
              </td>
              <td>
                {{ allUser.email }}
              </td>
              <td>
                {{ allUser.cpf }}
              </td>
              <td>
                {{ allUser.permission }}
              </td>
              <td>
                <div v-if="tipo == 'admin'">
                  <a href="#" class="btn bg-gradient-primary btn-sm">
                    <i class="fa fa-edit" data-toggle="tooltip" data-placement="top" title="Editar"></i>
                    Editar
                  </a>
                </div>
                <div v-else>
                  Sem ações
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import config from "../config";
axios.create({
  baseURL: config.baseUrl,
});
export default {
  props: ["tipo"],
  data() {
    return {
      allUsers: [],
    };
  },
  methods: {
    getAllUsers() {
      axios
        .get("api/getAllUsers")
        .then((response) => {
          this.allUsers = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getAllUsers();
  },
  created() {
    console.log("Component created DataTableUser.");
  },
};
</script>